import React, { Component } from 'react';
import {  Grid, Header, Card, Image, Container, Modal} from 'semantic-ui-react'

class Items extends Component {
  itemDescription = 'Whatever your decore need we have a concrete furniture solution. A few example items are below. Let us know your design and we can make something custom to match your needs! Check out our instagram for up to date pictures of our current projects. '
  items = [
        {
        image:'./stool.png',
        header:'Planter Stool',
        meta:'smooth concrete',
        description:'Smooth concrete stools any length for plants, bars, or kids.',
        },
        {
        image:'./coffee.png',
        header:'Coffee Table',
        meta:'smooth concrete',
        description:'Smooth concrete stools any length for plants, bars, or kids.',
        },
        /*{
        image:'./table.png',
        header:'Table',
        meta:'smooth concrete',
        description:'Smooth concrete stools any length for plants, bars, or kids.',
        },*/
        {
        image:'./Item1.jpg',
        header:'Patio Table',
        meta:'smooth concrete',
        description:'Smooth concrete stools any length for plants, bars, or kids.',
        },
        {
        image:'./Item2.jpg',
        header:'Coffee Table',
        meta:'smooth concrete',
        description:'Smooth concrete stools any length for plants, bars, or kids.',
        },
        {
        image:'./Item3.jpg',
        header:'Deck Table',
        meta:'smooth concrete',
        description:'Smooth concrete stools any length for plants, bars, or kids.',
        },
        {
        image:'./Item4.jpg',
        header:'Burnt Wood Legs',
        meta:'smooth concrete',
        description:'Smooth concrete stools any length for plants, bars, or kids.',
        },
    ];

    render(){
        return (
          <Container text id='Items'>
            <Header as='h1' dividing textAlign='center'>Items</Header>
            <p>
              {this.itemDescription}
              <a href='https://www.instagram.com/caswellfurniture/'>Instagram Link</a>
            </p>
            <Grid columns = {3} centered stackable >
              <Grid.Row centered>
              {this.items.map(
                (item)=>
                <Grid.Column key={item.header}>
                  <Modal
                  centered
                  trigger={
                  <Card>
                  <Image as={"div"} src={item.image} style={{height:"180px",overflow:"hidden"}} />
                  <Card.Content centered image>
                    <Card.Header>{item.header}</Card.Header>
                    {/*<Card.Meta>{item.meta}</Card.Meta>
                    <Card.Description>{item.description}</Card.Description>
              */}
                  </Card.Content>
                  </Card>}
                  header={item.header}
                  content={<Image fluid src={item.image}/>}/>
              
                </Grid.Column>
                )
                }
            </Grid.Row>
          </Grid>
          </Container>
        );
    }
}

export default Items;
