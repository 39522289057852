import React, { Component } from 'react';
import { Container, Header } from 'semantic-ui-react';

class AboutUs extends Component {
    aboutUs = "We are a family owned business with more than 20 years in the concrete industry. We are now utilizing our knowledge for making strong structures and applying it to the homedecor world. We are creating furniture for indoor and outdoor applications with beautiful finishes that will last a lifetime!";
    render(){
        return (
          <Container text id='AboutUs'>
            <Header as='h1' dividing textAlign='center'>About Us</Header>
            <p>{this.aboutUs}</p>
          </Container>
        );
    }
}

export default AboutUs;