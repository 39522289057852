import React, { Component, createRef } from 'react';
import { Button, Grid, Icon, Sticky, Ref, Segment, Menu, Image, Container} from 'semantic-ui-react'
import './App.css';
import AboutUs from './AboutUs';
import Items from './Items';
import Location from './Location';
import ContactUs from './ContactUs';

function App() {
  return (
    <LandingPage />  
  );
}

class LandingPage extends Component{
  constructor(props){
    super(props);
    this.state = { activeItem:'AboutUs' };
  }

  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name.replace(' ','') });
  }
  contextRef = createRef();

  render(){
    if(this.state.activeItem){
    }
    const { activeItem } = this.state;
    return(
      <Ref innerRef={this.contextRef}>
        <div>
        <Container fluid>
          <Image fluid src={process.env.PUBLIC_URL + '/background1_new.png'}/>
        <Sticky context={this.contextRef}>
          <Segment>
          <Menu text secondary >
            <Menu.Item name='AboutUs' active={activeItem==='AboutUs'} as="a" href="#AboutUs" onClick={this.handleItemClick}/>
            <Menu.Item name='Items' active={activeItem==='Items'} as="a" href="#Items" onClick={this.handleItemClick}/>
            <Menu.Item name='Location' active={activeItem==='Location'} as="a" href="#Location" onClick={this.handleItemClick}/>
            <Menu.Item name='ContactUs' active={activeItem==='ContactUs'} as="a" href="#ContactUs" onClick={this.handleItemClick}/>
          </Menu>
        </Segment>
        </Sticky>
        </Container>
        <Container fluid>
          <Image fluid src={process.env.PUBLIC_URL + '/background2.png'}/>
          <AboutUs/>
          <Image fluid src={process.env.PUBLIC_URL + '/background3.png'}/>
          <Items/>
          <Image fluid src={process.env.PUBLIC_URL + '/background4.png'}/>
          <Location/>
          <Image fluid src={process.env.PUBLIC_URL + '/background5.png'}/>
          <ContactUs/>
      <Segment inverted tertiary >
        <Grid columns = {3} centered stackable >
        <Grid.Row>
        </Grid.Row>
        <Grid.Row>
          <Button icon='instagram' as='a' href='https://www.instagram.com/caswellfurniture/'></Button>
          <Button icon='facebook' as='a' href='https://www.facebook.com/Caswell-Furniture-330506694314395/'></Button>
        </Grid.Row>
        <Grid.Row>
          <p><Icon size='small' name="copyright outline"/>2019, Caswell Furniture. All rights reserved</p>
        </Grid.Row>
        </Grid>
      </Segment>
      </Container>
      </div>
    </Ref>

  );
}
}

export default App;
