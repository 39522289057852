import React, { Component } from 'react';
import { Container, Header } from 'semantic-ui-react';

class Location extends Component {
  location = "Wherever you live we can find a way to ship our furniture. We ship nation wide. Based out of Johnstown, Ohio we do offer a discount to you if you ship within Ohio. Contact us now to find out more!"
    render(){
        return (
          <Container text id='Location'>
            <Header as="h1" textAlign='center' dividing>Location</Header>
                <p>{this.location}</p>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d48795.53949352293!2d-82.72392784676221!3d40.148493225535766!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8838438018397bcb%3A0xc104df3573dd9b2f!2sJohnstown%2C+OH+43031!5e0!3m2!1sen!2sus!4v1560248738827!5m2!1sen!2sus" 
                  width="90%" 
                  height="400" 
                  frameBorder="0" 
                  allowFullScreen
                  title='location'
                  ></iframe>
          </Container>
        );
    }
}

export default Location;