import React, { Component } from 'react';
import { Message, Container, Header, Form } from 'semantic-ui-react';
import axios from 'axios';
//import ModalTOS from './tos.js';
import isEmail from 'validator/lib/isEmail';
import isPhone from 'validator/lib/isMobilePhone';
import isAlpha from 'validator/lib/isAlpha';
import CustomField from './CustomField';

class ContactUs extends Component {
    constructor(props){
      super(props);
      this.state = {
        formData:{
          firstName:'',
          lastName:'',
          email:'',
          phone:'',
          question:'',
        },
        fieldErrors:{},
        errors: false,
        sent: false
      }
    }
    handleChange = ({name, value, error}) => {
      //const target = evt.target;
      //console.log(target);
      const formData = Object.assign({}, this.state.formData);
      const fieldErrors = Object.assign({}, this.state.fieldErrors);
      formData[name] = value;
      fieldErrors[name] = error;
      this.setState({formData, fieldErrors});
      console.log(this.state.formData);
  }    
  validate () {
    const formData = this.state.formData;
    const fieldErrors = Object.assign({},this.state.fieldErrors);

    if(!formData.firstName) fieldErrors['firstName']='First Name Required';
    if(!formData.email) fieldErrors['email']='Email Required';
    if(!formData.question) fieldErrors['question']='Question Required';
    this.setState({fieldErrors});
    const errMessages = Object.keys(fieldErrors).filter((k) => fieldErrors[k]);
    if (errMessages.length) return true;
    console.log('no errors');

    return false;
  }
  handleSubmit = (ev) => {
    const formData = this.state.formData;
    if (this.validate()){
      this.setState({errors:true});
      console.log(this.state);
      return;
    } 
    else{
      this.setState({errors:false});
    }
    axios.post('https://api.caswellfurniture.com/contact',
              formData)
              .then((response)=>{
                console.log(response)
                this.setState({sent:true});
              })
              .catch((error)=>{
                console.log(error);
              });
  }
    render(){
        return (
          <Container text textAlign="center" id='ContactUs'>
            <Header as="h1" dividing>Contact Us</Header>
            <Form
              error = {this.state.errors}
              success = {this.state.sent}
            >
            <Form.Group widths='equal'>
              <CustomField fluid label='First name*' 
                          placeholder='John' 
                          name='firstName'
                          value={this.state.formData.firstName}
                          onChange={this.handleChange}
                          validate={val => (isAlpha(val) ? false : 'First name must be alphabet letters only.')}
                          />
              <CustomField fluid label='Last name' 
                          placeholder='Doe' 
                          name='lastName'
                          value={this.state.formData.lastName}
                          onChange={this.handleChange}
                          validate={val => (isAlpha(val) ? false : 'Last name must be alphabet letters only.')}
                          />
            </Form.Group  >
            <Form.Group widths='equal'>
              <CustomField fluid label='Phone Number' 
                          placeholder='216-123-4567' 
                          name='phone'
                          value={this.state.formData.phone}
                          onChange={this.handleChange}
                          validate={val => (isPhone(val, 'en-US', {strictMode:false}) ? false : 'Invalid phone number.')}
                          />
              <CustomField fluid label='Email*' 
                          placeholder='john@doe.com' 
                          name='email'
                          type='email'
                          value={this.state.formData.email}
                          onChange={this.handleChange}
                          validate={val => (isEmail(val) ? false : 'Invalid email address.')}
                          />
            </Form.Group >
            <CustomField  label='Question*' 
                           type='TextArea'
                           placeholder='Ask any question aobut our business and services...' 
                           name='question'
                           value={this.state.formData.question}
                           onChange={this.handleChange}
                           />
            {/*    
            <Form.Group widths='equal'>
            <Form.Field
                            name='TermsAgreed'
                            >
                            <input type='checkbox' 
                            onClick={this.handleChange}
                            checked={this.formData.}
                            />

            </Form.Field>
            <ModalTOS />
            </Form.Group>
            */}
            <Message>
              <p>*Required Fields</p>
            </Message>
            <Message
                error
            >
              {Object.values(this.state.fieldErrors).filter(x => x).map(v => <li>{v}</li>)}
            </Message>
            <Message
                success
                header='Question successfully sent!'
                content='Your concerns are of utmost importance to us, we will get back to you as soon as possible, and thanks for your interest in our company!'
            />
                            
            <Form.Button onClick={this.handleSubmit}>Submit</Form.Button>
            </Form>
          </Container>
        );
    }
}

export default ContactUs;