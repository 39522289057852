import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';

class CustomField extends Component {
  static propTypes = {
    placeholder: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    validate: PropTypes.func,
    onChange: PropTypes.func.isRequired
  };

  state = {
    value: this.props.value,
    error: false
  };

  static getDerivedStateFromProps(nextProps) {
    return {value: nextProps.value}
  }

  onChange = evt => {
    const name = this.props.name;
    const value = evt.target.value;
    const error = this.props.validate ? this.props.validate(value) : false;

    this.setState({value, error});

    this.props.onChange({name, value, error});
  };

  render() {
    if(this.props.type==='TextArea'){
      return(
        <Form.TextArea
          fluid label = {this.props.label}
          placeholder={this.props.placeholder}
          value={this.state.value}
          onChange={this.onChange}
          error={this.state.error?true:false}
        />
      )

    }
    return (
        <Form.Input
          fluid label = {this.props.label}
          placeholder={this.props.placeholder}
          value={this.state.value}
          onChange={this.onChange}
          error={this.state.error?true:false}
        />
    );
  }
};

export default CustomField;
